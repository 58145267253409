@import "../../module.variables.scss";

body {
  overflow: auto;
}

.Table {
  position: relative;

  .fetching {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $borderRadius;
    padding: 30px;
    z-index: 2;

    &.float {
      position: absolute;
      left: 0;
      background: rgba($color: $white, $alpha: 0.8);
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      font-size: 16px;
      font-weight: 700;
      color: #adb7be;
      text-align: left;
      padding: 15px;
      user-select: none;
    }

    td {
      text-align: left;
      padding: 20px 15px;
      font-weight: 400;
      color: $dark;
    }

    // ============================ Sort style ================================
    .enableSort {
      padding-right: 18px;
      position: relative;
      cursor: pointer;

      &:before,
      &:after {
        opacity: 0;
        border: 4px solid transparent;
        content: "";
        display: block;
        height: 0;
        right: 5px;
        top: 50%;
        position: absolute;
        width: 0;
      }

      &:before {
        border-bottom-color: $grey;
        margin-top: -9px;
      }

      &:after {
        border-top-color: $grey;
        margin-top: 1px;
      }

      &.increase {
        &:before,
        &:after {
          opacity: 1;
        }

        &:before {
          border-bottom-color: $white;
        }
      }

      &.descrease {
        &:before,
        &:after {
          opacity: 1;
        }

        &:after {
          border-top-color: $white;
        }
      }
    }
    // ============================ End Sort style ============================

    tbody.Table__Body {
      background: $white;
      box-shadow: $boxShadow;
      border-radius: $borderRadius;

      // ============================ Column border bottom style ================================
      tr {
        border-bottom: 1px solid $borderColor;

        &:last-child {
          border-bottom: 0;
        }
      }
      // ============================ End Column border bottom style ============================

      // ============================ Body border radius style ================================
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: $borderRadius;
          overflow: hidden;
        }

        td:last-child {
          border-bottom-right-radius: $borderRadius;
          overflow: hidden;
        }
      }

      tr:first-child {
        td:first-child {
          border-top-left-radius: $borderRadius;
          overflow: hidden;
        }

        td:last-child {
          border-top-right-radius: $borderRadius;
          overflow: hidden;
        }
      }
      // ============================ End Body border radius style ============================
    }

    // ============================ Filter style ================================
    tr {
      &.filter {
        td,
        th {
          padding-top: 0px;
          padding-bottom: 10px;

          .item {
            width: 100%;
          }
        }
      }
    }
    // ============================ End Filter style ============================
  }

  .Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }

  .PaginationInfo {
    color: $grey;
  }

  .Pagination {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    .btnPagination {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 35px;
      cursor: pointer;
      border-style: none;
      background: rgba(70, 128, 255, 0);
      outline: none;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
      -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
      -moz-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
      -ms-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
      -o-transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
      svg {
        height: 12px;
        * {
          fill: $grey;
        }
      }
      &:hover {
        background: rgba(70, 128, 255, 0.2);
        // border: 1px solid $primary;
        // svg * {
        //     fill: $primary;
        // }
      }
      &:disabled {
        opacity: 0.3;
        cursor: default;
        &:hover {
          background: rgba(70, 128, 255, 0);
          // border-color: rgba($color: $primary, $alpha: 0);
          // svg * {
          //     fill: $grey;
          // }
        }
      }
    }

    .pageNumber {
      color: $grey;
      height: 30px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .Message {
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100px;
  }

  &.hide {
    display: none;
  }
}

.Table.scroll {
  box-shadow: $boxShadow;
  border-radius: $borderRadius;

  &.doubleScroll {
    overflow: auto;
  }

  &.insideScroll {
    max-height: 500px;
    overflow: auto;
  }

  table {
    position: relative;

    thead.Table__Filter {
      tr {
        td,
        th {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }

    thead.Table__Head {
      tr {
        th {
          position: sticky;
          top: 0;
          background: $main-background-color;
          color: #adb7be;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    tbody {
      box-shadow: none;

      // ============================ Body border radius style ================================
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 0 !important;
          overflow: hidden;
        }

        td:last-child {
          border-bottom-right-radius: 0 !important;
          overflow: hidden;
        }
      }

      tr:first-child {
        td:first-child {
          border-top-left-radius: 0 !important;
          overflow: hidden;
        }

        td:last-child {
          border-top-right-radius: 0 !important;
          overflow: hidden;
        }
      }
      // ============================ End Body border radius style ============================
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #e6e6e6;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cccaca;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    &:hover {
      background: #0b1b3f;
    }
  }
}

.Table__SearchBox {
  margin-bottom: 10px;

  &.hasValue {
    .input {
      border-color: $primary !important;
    }
  }

  .input {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: $borderRadius;
    border: 1px solid rgba($color: $borderColor, $alpha: 0);
    transition: border 0.2s ease;

    &:focus-within {
      border: 1px solid $borderColor;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      svg {
        height: 15px;

        * {
          fill: $grey;
        }
      }
    }

    input {
      outline: none;
      border: none;
      font-size: 1em;
      color: $dark;
      width: 400px;
      max-width: 100%;
      background: transparent;

      &::placeholder {
        color: $grey;
        font-weight: 300;
      }
    }

    .btnClear {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      opacity: 0.7;

      svg {
        height: 10px;

        * {
          fill: $grey;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .suggests {
    margin-top: 15px;
    background: $white;
    box-shadow: $boxShadow;
    overflow: hidden;
    border-radius: $borderRadius;

    .item {
      display: flex;
      align-items: center;
      padding: 15px;
      cursor: pointer;
      border-bottom: 1px solid $borderColor;

      &.focused {
        background: rgba($color: $primary, $alpha: 0.05);
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .Message {
    margin: 30px 0;
  }
}

.Table__FilterBox {
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: $borderRadius;
  border: 1px solid $input-border-color;
  display: flex;
  flex-direction: column;
  background: $main-background-color;

  > .Table__FilterBox__Label {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    justify-content: space-between;

    .content {
      color: $grey;
      font-weight: 500;
      display: flex;
      align-items: center;

      svg {
        height: 15px;
        max-width: 15px;
        margin-right: 5px;

        * {
          fill: $grey;
        }
      }
    }

    .iconToggle {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 5px;

        * {
          fill: $grey;
        }
      }
    }
  }

  &.openFilter {
    > .Table__FilterBox__Label {
      .content {
        color: $white;

        svg * {
          fill: $white;
        }
      }

      .iconToggle {
        transform-origin: center center;
        transform: rotate(180deg);
      }
    }
  }

  .List {
    margin-top: 10px;
    margin-bottom: -15px;

    .Item {
      display: inline-flex;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 15px;

      > .label {
        margin-bottom: 5px;
        color: $grey;
        font-size: 0.9em;
      }
    }
  }
  .search-button {
    margin-top: 12px;
    .Button {
      padding: 0 32px 0 16px;
    }
  }
}
