@import "./role-permission";
@import "./change-password";
@import "./announcement";
@import "./leverage";
@import "./change-wallet";
@import "./maintenance";
@import "./met-conversion-config";
@import "./mec-mining-config";
@import "./met-pool-promotion";

.withConfigWraper {
    .TabLinks {
        .swiper-container {
            .swiper-wrapper {
                .swiper-slide {
                    width: auto;
                }
            }
        }
    }
}